import { Injectable } from '@angular/core';
import { UrlTree, CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
  ) { }

  async canActivate(): Promise<UrlTree | boolean> {
    const isLoggedIn = this.auth.isLoggedIn();
    return isLoggedIn ? true : this.router.parseUrl('/login');
  }
}
