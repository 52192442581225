import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router
  ) { }

  authenticate(cs: any) {
    localStorage.setItem('username', cs);
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.removeItem('username');
    this.router.navigate(['/', 'login']);
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  isLoggedIn() {
    const username = this.getUsername();
    return !!username;
  }


}
