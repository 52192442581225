import { Component, OnInit } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
  ) {
  }

  ngOnInit() {
    this.platform.ready().then(() => {
      this.initPermissions();
    })
  }

  initPermissions() {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_NOTIFICATION_POLICY).then(
      result => {
        console.log('Has permission?',result.hasPermission)
        if (!result.hasPermission) {
          this.androidPermissions.PERMISSION.ACCESS_NOTIFICATION_POLICY
        }
      },
      err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_NOTIFICATION_POLICY)
    );
  }


}
